:root {
  --mobile-width: 420px;

  --color-bg: #ffffff;


  --offset: 15px;

  --primary: #0C8CE9;
  --primary-button-text-color: white;

  --secondary-button-bg-color: #EEF7FF;
  --quiz-question-title-color: #282D3C;

  --input-border-color: var(--primary);
  --input-placeholder-color: gray;
  --input-text-color: #373A41;

  --calendar-primary-color: #373a41;
  --calendar-secondary-color: #ACAEBB;

  --quiz-input-background-color: #F3F5F7;
  --quiz-input-text-color: #282D3C;
  --unchecked-background-opacity: 1;

  /*font-family: 'Gilroy-Bold', sans-serif;*/
  /*font-family: 'Gilroy-Heavy', sans-serif;*/
  /*font-family: 'Gilroy-Light', sans-serif;*/
  /*font-family: 'Gilroy-Medium', sans-serif;*/
  /*font-family: 'Gilroy-Regular', sans-serif;*/
}



[data-theme="dark"] {
  --color-bg: #0D0D0D;

  --primary: #1B99F3;
  --primary-button-text-color: #0D0D0D;

  --secondary-button-bg-color: #141414;
  --quiz-question-title-color: #C3C8D7;

  --input-border-color: gray;
  --input-placeholder-color: white;
  --input-text-color: white;

  --calendar-primary-color: #ACAEBB; /*Just swap*/
  --calendar-secondary-color: #373a41;

  --quiz-input-background-color: #171717;
  --quiz-input-text-color: #ACAEBB;
  --unchecked-background-opacity: 0.1;
}


body {
  font-family: 'Gilroy-Regular', sans-serif;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
}

.App {
  text-align: center;
  max-width: 420px;
  width: 100%;
  background-color: var(--color-bg);

  color: #898989;
}

.habit-name {
  color: var(--calendar-primary-color);
}
.habit-date {
  color: var(--calendar-secondary-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0em;
}

.habit-container {
  position: relative;
  color: #373A41;

  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
}
.habit-container.intersects {
  background-color: pink;
}
.habit-editing-icon {
  position: absolute;
  right: 5px;
  top: 5px;
  bottom: 5px;
  width: 35px;
  height: 35px;
}

.habit-date-error {
  color: red;
  font-weight: bold;
}

.base-container {
  text-align: center;
  max-width: 420px;
  width: 100%;
}
.menu-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: -1px;
  text-align: left;
  padding-left: var(--offset);
  color: #373A41;

  border-bottom-color: #0000004D;
  border-bottom-style: solid;
  border-bottom-width: thin;
  padding-bottom: 15px;
  padding-top: 5px;
  margin-bottom: 15px;
}

.habit-name-editing {
  position: relative;
  margin-bottom: 20px;
}
.habit-name-editing label {
  position: absolute;
  top: 5px;
  left: 17px;
}
.error {
  color: #DC3545;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
}

.habit-name-editing-input.error {
  color: #DC3545;
}
.habit-name-editing-input {
  width: 100%;
  height: 58px;

  padding-left: 15px;

  border-color: var(--input-border-color);
  border-radius: 8px;
  border-width: 1px;

  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

  color: var(--input-text-color);
  background-color: var(--color-bg);

  box-sizing: border-box;
}
.new-habit-input {
  width: 100%;
  height: 28px;

  margin-bottom: 18px;
  margin-top: 2px;


  border-radius: 8px;
  border-color: #1D2227;

  text-indent: 16px;

  font-size: medium;
  background-color: var(--color-bg);
  color: var(--input-text-color);
  box-shadow: none;
  border-width: 1px;
}

.time-picking-container {
  /*grid-template-columns: auto auto auto;*/
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  display: grid;
  justify-content: center;
}
button {
  /*border: 0;*/
  border-radius: 8px;
  height: 48px;
}
.time-picking-button {
  height: 32px;
  border-radius: 8px;
  border: 0;
  width: 115px;

  background-color: #F1F9FF;
  color: var(--primary)
}
.time-picking-button.chosen {
  background-color: var(--primary);
  color: white;
}

.plan-day-container {
  position: relative;
  /*height: 100%;*/
  /*width: 100%;*/
}
.popup {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  /*background-color: gray;*/
  background-color: #000000b5;
  color: white;

  position: fixed;
  z-index: 1000;
  text-align: center;
  max-width: 420px;
  width: 100%;
}

.popup-label {
  color: red;
  font-weight: bold;
  margin-bottom: 8px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

button.primary {
  background-color: var(--primary);
  color: var(--primary-button-text-color);
  border: 0;
  box-shadow: none;
}
button.secondary {
  border: 0;

  background-color: var(--secondary-button-bg-color);
  color: var(--primary);

  box-shadow: none;
}
button.remove {
  background-color: white;
  color: #ED584E;
  border-color: #E7EAED;
  border-width: thin;
  box-shadow: none;
}

.habit-created-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: -0.015em;
  text-align: center;
  color: #373A41;
}

.full {
  width: 100%;
}

.thumbs-up {
  width: 144px;
  height: 144px;
  margin-top: 161px;
}
.no-habits-img {
  width: 156px;
  height: 138px;

  margin-top: 110px;
}

.quiz-navigation-container {
  display: grid;
  margin-top: 32px;
}
.quiz-navigation-container.two {
  grid-template-columns: auto auto;
}

.quiz-input-answer-remaining-symbols {
  position: absolute;
  right: 60px;
  top: 16px;
}

.quiz-input-answer {
  /*width: 343px;*/
  height: 128px;
  /*padding: 16px 20px 16px 20px;*/
  padding: 16px 76px 16px 20px;
  border-radius: 8px;
  /*text-indent: 16px;*/
  border: 0;
  /*gap: 20px;*/


  background-color: var(--quiz-input-background-color);
  color: var(--quiz-input-text-color);

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  /*text-align: left;*/
}
.quiz-topics-container {
  margin-top: 24px;
  margin-bottom: 24px;
  display: grid;
  align-content: center;
  grid-auto-flow: column;
  margin-left: 50%;
  column-gap: 10px;
}
.quiz-topics-item {
  height: 32px;
  line-height: 32px;

  width: 64px;

  box-shadow: 8px 8px 32px 0px #B7C5EA36;
  color: #ACAEBB;

  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.01em;
  text-align: center;
  border-radius: 12px;
}
.quiz-topics-item.chosen {
  height: 48px;
  line-height: 48px;

  width: 100px;
  color: var(--primary);
  font-size: 14px;
}

.habit-created-close {
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
}

.habit-created-description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: center;

  margin-bottom: 25px;

  color: #8C94A8;
}

.primary.disabled {
  background-color: #EEF7FF;
  color: #A3D4FF;
}

.coach-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;

  color: #373A41;
}
.coach-title span {
  color: var(--primary);
}
.coach-goals {
  margin-top: 24px;
}
.coach-goals-item {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
  margin-bottom: 12px;

  color: var(--primary);
}
.coach-session-description {
  margin-top: 24px;

  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: center;

  color: #ACAEBB;
}
.coach-session-start-img {
  width: 166px;
  height: 122px;

  margin-top: 12px;
  margin-bottom: 22px;
}
.coach-session-started-already-img {
  width: 200px;
  height: 200px;

  /*margin-top: 60px;*/
  margin-top: 30px;
  /*margin-bottom: 24px;*/
  margin-bottom: 0px;
}

.quiz-done-button {
  margin-top: 44px;
}
.quiz-done-button.disabled {
  color: #A3D4FF;
}

.quiz-answers-container button {
  height: 32px;
}

.quiz-question-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.015em;
  text-align: center;

  margin-top: 24px;
  margin-bottom: 16px;

  color: var(--quiz-question-title-color)
}

.quiz-answers-container {
  display: grid;
  gap: 8px;
  grid-auto-flow: column;
}
.quiz-answers-container.vertical {
  grid-auto-flow: row;
}

.quiz-lets-start {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.01em;
  text-align: center;

  color: #788095;
}

.footer-menu-click-badge {
  position: absolute;
  right: 44px;
  top: 10px;

  background-color: red;
  border-radius: 8px;
  height: 8px;
  width: 8px;
}

.footer-menu-wrapper {
  position: relative;
}
.footer-menu-text {
  position: absolute;
  left: 0;
  bottom: 12px;
  right: 0;

  font-size: 10px;
  font-weight: 500;
  line-height: 10px;
  letter-spacing: 0em;
  text-align: center;
  color: #ACAEBB;
}
.footer-menu-text.chosen {
  color: var(--primary);
}
.footer-grid {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 30% 30% 30%;
  /*grid-template-columns: repeat(3, 1fr)*/
}
.footer {
  position: fixed;
  width: var(--mobile-width);
  height: 62px;
  /*padding: 12px 16px 12px 16px;*/
  border-radius: 16px 16px 0px 0px;
  gap: 10px;
  bottom: 0;
  left: 0;
  right: 0;

  box-shadow: 0px 0px 1px 0px #282D3C08;
  box-shadow: 0px 0px 2px 0px #282D3C0D;
  box-shadow: 0px -4px 8px 0px #282D3C0A;
}
.footer-menu-img {
  /*width: 16px;*/
  /*height: 16px;*/
  margin-bottom: 7px;
  margin-top: 16px;
}
.footer-menu-img.account {
  /*width: 12px;*/
  /*height: 18px;*/
}

.new-habit-button-wrapper {
  /*position: fixed;*/
  /*bottom: 78px;*/
  /*left: var(--offset);*/
  /*right: var(--offset);*/
  margin-left: var(--offset);
  margin-right: var(--offset);
  /*width: calc(var(--mobile-width) - var(--offset) - var(--offset));*/
}

.new-habit-button {
  border: 0;
  border-radius: 8px;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;

  width: 100%;
  text-align: center;
}

.close {
  width: 200px;
  height: 40px;
}
.from-to-form {
  min-height: 150px;
}
.wrapper {
  padding-left: var(--offset);
  padding-right: var(--offset);
  overflow-x: hidden;
}
.new-habit-footer-wrapper {
  /*position: fixed;*/
  width: 100%; /*var(--mobile-width);*/
  bottom: 0;
  left: 0;
  right: 0;
}
.new-habit-footer {
  display: grid;
  flex-direction: column;
  /*grid-template-columns: 150px repeat(5, 1fr);*/
  grid-template-columns: auto auto;
  grid-gap: 15px;
}
.new-habit-button-confirm.disabled {
  /*color: gray;*/
  /*border-color: gray;*/
  opacity: 0.3;
}
.new-habit-button-confirm {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 60px;
  font-size: large;
}



.left {
  text-align: left;
  margin-left: var(--offset);
  /*padding-left: 10px;*/
  /*width: 150px*/
}

.calendar-day {
  color: #ACAEBB;
}

.current-day {
  color: var(--primary);
}

.habits-table-wrapper {
  height: calc(100vh - 72px - 62px);
  width: 100%;
  overflow-y: scroll;
}
.habits-table {
  width: 100%;
  /*height: 100%;*/
  /*height: 50px;*/

  display: grid;
  flex-direction: column;
  /*grid-template-columns: 150px 20% 20% 20% 20% 20%;*/
  grid-template-columns: 150px repeat(5, 1fr);
  gap: 10px;
  /*padding: 10px;*/
}
.habit-checkbox {
  width: 25px;
  height: 25px;

/*input[type="checkbox"] {*/
/*  accent-color: yellow*/
/*}*/
}
.habit-checkbox:not(:checked){
  opacity: var(--unchecked-background-opacity);
}
.intersecting-habits-warning {
  color: red;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}





.light {
  border-radius: 100%;
  background: #222;
  margin: 10px;

  height: 74px;
  width: 74px;
}
.light-strip {
  /*margin: 0 5px;*/
  border-radius: 14px;

  background: black;
  height: 350px;
  /*width: 94px;*/


  flex: 0 0 25%;
  padding: 10px;
}

.racing-title {
  padding-top: 10px;
  font-size: 20px;
}

.racing-strip-container {
  display: flex;
  flex-wrap: wrap;
}

.green {
  background: green;
}
.red {
  background: red;
}